export class Rider {
  id = null;
  allocationMatches = [];
  car = null;
  code = null;
  isDeleted = false;
  etc = null;
  memo1 = null;
  memo2 = null;
  mobile1 = null;
  mobile2 = null;
  name = "";
  nickname = "";
  riderGroup1 = null;

  constructor(rider) {
    this.id = rider.id;
    this.allocationMatches = rider.allocationMatches || [];
    this.car = rider.car;
    this.code = rider.code;
    this.isDeleted = rider.isDeleted;
    this.etc = rider.etc;
    this.memo1 = rider.memo1;
    this.memo2 = rider.memo2;
    this.mobile1 = rider.mobile1;
    this.mobile2 = rider.mobile2;
    this.name = rider.name;
    this.nickname = rider.nickname;
    this.riderGroup1 = rider.riderGroup1;
  }

  get allocationGroupsName() {
    let allocationGroups = [];

    this.allocationMatches.forEach(match => {
      if (match.allocationGroup) {
        allocationGroups.push(match.allocationGroup.name);
      }
    });

    return allocationGroups.join(", ");
  }

  get etcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get riderGroup1Name() {
    if (this.riderGroup1) {
      return this.riderGroup1.name;
    } else {
      return null;
    }
  }

  get riderGroup1Id() {
    if (this.riderGroup1) {
      return this.riderGroup1.id;
    } else {
      return null;
    }
  }

  get riderGroup2Name() {
    if (this.riderGroup1 && this.riderGroup1.riderGroup2) {
      return this.riderGroup1.riderGroup2.name;
    } else {
      return null;
    }
  }

  get riderGroup2Id() {
    if (this.riderGroup1 && this.riderGroup1.riderGroup2) {
      return this.riderGroup1.riderGroup2.id;
    } else {
      return null;
    }
  }

  get sharingLocation() {
    return !!this.etcObj?.sharingLocation || false;
  }
}
