import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _findIndex from "lodash/findIndex";
import dfIsAfter from "date-fns/isAfter";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { DeliveryModal, SetMisclassificationModal } from "@/Modals";
import { DeliveriesStore } from "@/store";
import {
  useDeliveriesMisclassificationStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ButtonsCol = styled(Col)`
  margin-left: -2.5px;
  margin-right: -2.5px;

  button {
    margin-left: 2.5px;
    margin-right: 2.5px;
    margin-bottom: 5px;
  }
`;

const CheckBoxRow = styled(Row)`
  margin-top: 5px;
`;

const ColFlex = styled(Col)`
  flex: ${({ flex }) => flex || 1};
  padding-left: 5px;
  padding-right: 5px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 0;
`;

const CounterCol = styled(Col)`
  display: inline-block;
  margin: 10px 0;
  text-align: right;
`;

const DatePickerCol = styled(Col)`
  padding: 0;
  display: flex;
  flex-direction: row;

  input {
    width: calc(50% - 15px);
  }
`;

const DeliveriesTable = styled(Table)`
  font-size: 14px;
  min-width: 1500px;
`;

const FormLabel = styled(Form.Label)`
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
  font-size: 14px;
`;

const FormRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const TableRow = styled(Row)`
  overflow: auto;
  flex: 1;
`;
const TableCol = styled(Col)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Wave = styled.span`
  margin: 0 5px;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ theme }) => `calc(100vh - ${theme.constants.NAV_HEIGHT})`};
`;

const ClickableColumn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const BookIdColumn = ({ delivery, onAfterUpdate }) => {
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(
      <DeliveryModal
        backdrop
        key="book-detail-modal"
        bookId={delivery.bookId}
        onAfterUpdate={onAfterUpdate}
        type={delivery.type || "1"}
      />,
    );
  }

  return (
    <ClickableColumn onClick={handleClick}>
      {delivery.renderBookId()}
    </ClickableColumn>
  );
};

const CounterContainer = memo(({ fetch, fetchTime }) => {
  const { loading } = useLoadingStore().state;
  const { openedModal } = useModalStore().state;

  const sec = 1000;
  const [count, setCount] = useState(600 * sec);
  const [pause, setPause] = useState(false);

  const handlePause = useCallback(() => {
    setPause(!pause);
  }, [pause]);

  const counter = useCallback(() => {
    if (count === 0) {
      setCount(600000);

      if (!loading) {
        fetch();
      }
    } else {
      setCount(count - sec);
    }
  }, [count, setCount, loading]);

  useEffect(() => {
    if (!pause && !openedModal) {
      let id = setInterval(counter, sec);
      return () => clearInterval(id);
    }
  }, [pause, openedModal, counter]);

  return (
    <CounterCol>
      {parseInt(count / 60000)}분 {(count % 60000) / 1000}초 남았습니다 (최근
      호출 시간 : {formatDate(getDateByTimeZone(fetchTime), "HH:mm:ss")}){" "}
      <SmallButton onClick={() => handlePause(!pause)} variant="warning">
        {pause || openedModal ? "시작" : "일시정지"}
      </SmallButton>
      <SmallButton onClick={fetch} variant="success">
        새로고침
      </SmallButton>
    </CounterCol>
  );
});

const SearchContainer = memo(() => {
  const { state, ...actions } = useDeliveriesMisclassificationStore();

  const { handleSubmit, register, reset, getValues, setValue } = useForm();

  useEffect(() => {
    reset({
      ...state.query,
    });
  }, []);

  const handleChange = useCallback((e) => {
    if (e.target.name === "dateFrom" && e.target.value > getValues().dateTo) {
      setValue("dateTo", e.target.value);
    }

    if (e.target.name === "dateTo" && e.target.value < getValues().dateFrom) {
      setValue("dateFrom", e.target.value);
    }
  }, []);

  const onSubmit = useCallback((data) => {
    actions.setQuery(data);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormRow>
        <ColFlex flex={4}>
          <Row>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="types" ref={register}>
                  <option value="1;2;3">전체</option>
                  <option value="1">일반</option>
                  <option value="2;3">반품/픽업</option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <ColFlex sm={1}>
              <Form.Group>
                <Form.Control as="select" custom name="dateType" ref={register}>
                  <option value={DeliveriesStore.QUERY_DATE_TYPE_RECEIPT_DATE}>
                    접수일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_ALLOCATION_DATE
                    }
                  >
                    배송배차일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE
                    }
                  >
                    수거완료일자
                  </option>
                  <option
                    value={
                      DeliveriesStore.QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE
                    }
                  >
                    배송완료일자
                  </option>
                </Form.Control>
              </Form.Group>
            </ColFlex>
            <DatePickerCol sm={4}>
              <Form.Control
                id="start"
                name="dateFrom"
                type="date"
                ref={register}
                onChange={handleChange}
              />
              <Wave>~</Wave>
              <Form.Control
                id="end"
                name="dateTo"
                type="date"
                ref={register}
                onChange={handleChange}
              />
            </DatePickerCol>
          </Row>
        </ColFlex>
        <ColFlex>
          <Row>
            <FormLabel column sm={4}>
              예약번호
            </FormLabel>
            <Col sm={8}>
              <Form.Control name="bookId" ref={register} />
            </Col>
          </Row>
        </ColFlex>
      </FormRow>
    </Form>
  );
});

const DeleteColumn = memo(({ bookId, onAfterUpdate }) => {
  const { ...actions } = useDeliveriesMisclassificationStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.setUnmisClassification(bookId);
        onAfterUpdate();
      } catch (e) {
        window.alert(`오분류 삭제에 실패했습니다: ${e.message}`);
      }
    }
  }, [bookId]);

  return (
    <SmallButton variant="danger" onClick={handleClickDelete}>
      삭제
    </SmallButton>
  );
});

export default memo(() => {
  const { state, ...actions } = useDeliveriesMisclassificationStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal } = useModalStore();
  const { getValues, register, setValue } = useForm();

  const [loaded, setLoaded] = useState(false); // 첫 fetch 진행

  // TODO: 리팩토링 필요
  const handleChangeCheckBoxForm = useCallback(() => {
    let _queryTypes = getValues("queryTypes");

    const includedAll = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );
    const addedAll = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
    );

    // 전체를 선택한 경우
    // 전체 해제된 경우
    if (_queryTypes.length === 0 || (!includedAll && addedAll)) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ALL];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    // 미배송
    const addedDelayed = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );
    const includedDelayed = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
    );

    if (addedDelayed && !includedDelayed) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedDelayed &&
      includedDelayed &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
      );
    }

    // 픽업 & 반품을 선택한 경우
    const addedTypePickup = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );
    const includedTypePickup = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
    );

    if (!includedTypePickup && addedTypePickup) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypePickup &&
      addedTypePickup &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP,
      );
    }

    const addedTypeReturn = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );
    const includedTypeReturn = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
    );

    if (!includedTypeReturn && addedTypeReturn) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedTypeReturn &&
      addedTypeReturn &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN,
      );
    }

    // 우편번호지역
    const addedCustomCustomerPostalCode = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
    );
    const includedCustomCustomerPostalCode = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
    );

    if (addedCustomCustomerPostalCode && !includedCustomCustomerPostalCode) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedCustomCustomerPostalCode &&
      includedCustomCustomerPostalCode &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE,
      );
    }

    // 취소 or 사고를 선택한 경우
    const addedAccident = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );
    const includedAccident = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
    );

    if (!includedAccident && addedAccident) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAccident &&
      addedAccident &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT,
      );
    }

    const addedCancel = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    const includedCancel = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
    );
    if (addedCancel && !includedCancel) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedCancel &&
      includedCancel &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED,
      );
    }

    // 송하인주소오류 / 송하인주소오류취소
    const addedSenderAddedAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    const includedSenderAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
    );
    if (addedSenderAddedAddressError && !includedSenderAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddedAddressError &&
      includedSenderAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR,
      );
    }

    const addedSenderAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    const includedSenderAddressErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
    );
    if (
      addedSenderAddressErrorCanceled &&
      !includedSenderAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedSenderAddressErrorCanceled &&
      includedSenderAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 수하인주소오류 / 수하인주소오류취소
    const addedReceiverAddressError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    const includedReceiverAddressError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
    );
    if (addedReceiverAddressError && !includedReceiverAddressError) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressError &&
      includedReceiverAddressError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR,
      );
    }

    const addedReceiverAddressErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
    );
    const includedReceiverAddressErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
    );
    if (
      addedReceiverAddressErrorCanceled &&
      !includedReceiverAddressErrorCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedReceiverAddressErrorCanceled &&
      includedReceiverAddressErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED,
      );
    }

    // 연락처오류 / 연락처오류취소
    const addedMobileError = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
    );
    const includedMobileError = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
    );
    if (addedMobileError && !includedMobileError) {
      const queryTypes = [DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedMobileError &&
      includedMobileError &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) => type !== DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR,
      );
    }

    const addedMobileErrorCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
    );
    const includedMobileErrorCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
    );
    if (addedMobileErrorCanceled && !includedMobileErrorCanceled) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      addedMobileErrorCanceled &&
      includedMobileErrorCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED,
      );
    }

    // 배송 불가 지역을 선택한 경우
    const addedAddressNotSupported = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );
    const includedAddressNotSupported = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
    );

    if (!includedAddressNotSupported && addedAddressNotSupported) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupported &&
      addedAddressNotSupported &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !== DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED,
      );
    }

    const addedAddressNotSupportedCanceled = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );
    const includedAddressNotSupportedCanceled = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
    );

    if (
      !includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled
    ) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAddressNotSupportedCanceled &&
      addedAddressNotSupportedCanceled &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED,
      );
    }

    // 배달 대행을 선택한 경우
    const addedAgencyCorpUser = _queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );
    const includedAgencyCorpUser = state.query.queryTypes.includes(
      DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
    );

    if (!includedAgencyCorpUser && addedAgencyCorpUser) {
      const queryTypes = [
        DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      ];
      setValue("queryTypes", queryTypes);
      actions.setQuery({
        queryTypes,
      });
      return;
    }

    if (
      includedAgencyCorpUser &&
      addedAgencyCorpUser &&
      _queryTypes.length > state.query.queryTypes.length
    ) {
      _queryTypes = _queryTypes.filter(
        (type) =>
          type !==
          DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN,
      );
    }

    let queryTypes = _queryTypes;
    if (queryTypes.indexOf(DeliveriesStore.QUERY_DELIVERY_TYPE_ALL) > -1) {
      const index = _findIndex(
        queryTypes,
        (t) => t === DeliveriesStore.QUERY_DELIVERY_TYPE_ALL,
      );
      queryTypes = [
        ...queryTypes.slice(0, index),
        ...queryTypes.slice(index + 1),
      ];
    }
    queryTypes = queryTypes.filter((t) => !!t);

    setValue("queryTypes", queryTypes);
    actions.setQuery({
      queryTypes,
    });
  }, [actions.setTypesQuery, state.query.queryTypes]);

  const [fetchTime, setFetchTime] = useState();

  const fetch = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          startLoading();

          await actions.fetchAll({ query: state.query });
          finishLoading();

          await actions.countByStatus({ query: state.query });

          setFetchTime(new Date());
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, actions.countByStatus, state.query]);

  const fetchWithoutCounting = useCallback(async () => {
    try {
      if (state.query) {
        const includedDelayed = state.query.queryTypes.includes(
          DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED,
        );

        if (!includedDelayed) {
          startLoading();

          await actions.fetchAll({ query: state.query });
          finishLoading();

          setFetchTime(new Date());
        } else {
          fetchDelayed();
        }
      }

      setLoaded(true);
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, actions.countByStatus, state.query]);

  const fetchDelayed = useCallback(async () => {
    try {
      if (state.query) {
        startLoading();

        await actions.fetchAllDelayed({ query: state.query });
        finishLoading();

        await actions.countByStatus({ query: state.query });
      }
    } catch (e) {
      window.alert(
        `배송정보를 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.countByStatus, actions.fetchAllDelayed, state.query]);

  useEffect(() => {
    handleClear();
    setValue("queryTypes", state.query.queryTypes);
  }, []);

  useEffect(() => {
    fetch();
  }, []);

  const [columnWidthsObj, setColumnWidthsObj] = useState({});

  useEffect(() => {
    const columnWidths = localStorage.getItem("columnWidths");

    if (columnWidths) {
      setColumnWidthsObj(JSON.parse(columnWidths));
    }
  }, []);

  useEffect(() => {
    if (loaded) {
      if (state.query.page > 1) {
        actions.setQuery({ page: 1 });
      } else {
        fetch();
      }
    }
  }, [
    state.query.allocationGroupName,
    state.query.bookId,
    state.query.corpUserName,
    state.query.dateType,
    state.query.dateFrom,
    state.query.dateTo,
    state.query.orderIdFromCorp,
    state.query.orderingNumberByDeliveryRiderId,
    state.query.receiverAddressRoad,
    state.query.receiverDong,
    state.query.receiverMobile,
    state.query.receiverName,
    state.query.region,
    state.query.rider,
    state.query.pickupRiderName,
    state.query.deliveryRiderName,
    state.query.senderDong,
    state.query.spotName,
    state.query.senderName,
    state.query.senderMobile,
    state.query.types,
  ]);

  useEffect(() => {
    if (loaded) {
      if (state.query.page > 1) {
        actions.setQuery({ page: 1 });
      } else {
        fetchWithoutCounting();
      }
    }
  }, [state.query.queryTypes]);

  useEffect(() => {
    if (loaded) {
      fetch();
    }
  }, [state.query.page, state.query.pageSize, state.query.orderBy]);

  useEffect(() => {
    if (loaded && state.reset) {
      fetch();
      actions.reset(false);
    }
  }, [state.reset]);

  useEffect(() => {
    const changedDateFrom = getDateByTimeZone(state.query.dateFrom);
    const changedDateTo = getDateByTimeZone(state.query.dateTo);
    if (dfIsAfter(changedDateFrom, changedDateTo)) {
      actions.setQuery({
        dateFrom: state.query.dateFrom,
        dateTo: state.query.dateTo,
      });
    }
  }, [state.query.dateFrom, state.query.dateTo]);

  const handleClear = useCallback(() => {
    actions.resetQuery();
  }, [actions.resetQuery]);

  const handleAfterDetailUpdate = useCallback(() => {
    fetch();
  }, [fetch]);

  const handleClickMisclassification = () => {
    openModal(
      <SetMisclassificationModal
        key="set-misclassfication-modal"
        onUpdate={handleAfterDetailUpdate}
      />,
    );
  };

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setQuery({ orderBy });
    },
    [actions.setQuery],
  );

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        id: "bookId",
        accessor: (row) => (
          <BookIdColumn
            delivery={row}
            onAfterUpdate={handleAfterDetailUpdate}
          />
        ),
        selectable: false,
        sortable: true,
        width: columnWidthsObj.bookId || 120,
      },
      {
        Header: "접수일시",
        accessor: (row) => row.renderReceiptDate(),
        id: "RECEIPT_DATE",
        sortable: true,
        width: columnWidthsObj.RECEIPT_DATE || 100,
      },
      {
        Header: "수지",
        accessor: (row) => row.renderPickupDateScheduled("HH:mm"),
        id: "PICKUP_SCHEDULED_DATE",
        sortable: true,
        width: columnWidthsObj.PICKUP_SCHEDULED_DATE || 60,
      },
      {
        Header: "수완",
        accessor: (row) => row.renderPickupDateCompleted("HH:mm"),
        id: "PICKUP_COMPLETED",
        sortable: true,
        width: columnWidthsObj.PICKUP_COMPLETED || 60,
      },
      {
        Header: "입고",
        accessor: (row) => row.renderWarehousedAt("HH:mm"),
        id: "WAREHOUSED",
        sortable: true,
        width: columnWidthsObj.WAREHOUSED || 60,
      },
      {
        Header: "배차",
        accessor: (row) => row.renderDeliveryAllocatedDate(),
        id: "DELIVERY_ALLOCATED",
        sortable: true,
        width: columnWidthsObj.DELIVERY_ALLOCATED || 90,
      },
      {
        Header: "연기",
        accessor: (row) => row.renderPostponedDate(),
        id: "DELIVERY_WAITING",
        sortable: true,
        width: columnWidthsObj.DELIVERY_WAITING || 90,
      },
      {
        Header: "배출",
        accessor: (row) => row.renderReleasedAt(),
        id: "DELIVERY_STARTED",
        sortable: true,
        width: columnWidthsObj.DELIVERY_STARTED || 90,
      },
      {
        Header: "배완",
        accessor: (row) => row.renderDeliveryCompletedDate(),
        id: "DELIVERY_COMPLETED",
        sortable: true,
        width: columnWidthsObj.DELIVERY_COMPLETED || 90,
      },
      {
        Header: "접수점",
        accessor: (row) => row.spotName,
        id: "SPOT_NAME",
        sortable: true,
        width: columnWidthsObj.SPOT_NAME || 150,
      },
      {
        Header: "수거 담당",
        accessor: (row) => row.pickupRiderName,
        id: "PICKUP_RIDER_NAME",
        sortable: true,
        width: columnWidthsObj.PICKUP_RIDER_NAME || 80,
      },
      {
        Header: "배송 담당",
        accessor: (row) => row.deliveryRiderName,
        id: "DELIVERY_RIDER_NAME",
        sortable: true,
        width: columnWidthsObj.DELIVERY_RIDER_NAME || 80,
      },
      {
        Header: "삭제",
        accessor: (row) => (
          <DeleteColumn
            bookId={row.bookId}
            onAfterUpdate={handleAfterDetailUpdate}
          />
        ),
        selectable: false,
        width: 100,
      },
    ],
    [handleAfterDetailUpdate, columnWidthsObj],
  );

  return (
    <Wrap>
      <Container>
        <Title>오분류 관리</Title>

        <SearchContainer />
        <Row>
          <ButtonsCol>
            <Button onClick={handleClickMisclassification}>오분류 등록</Button>
          </ButtonsCol>
        </Row>
        <CheckBoxRow>
          <Col>
            <Form onChange={handleChangeCheckBoxForm}>
              <Form.Check
                id="filter-all"
                inline
                label={`전체(${state.count.all})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ALL}
                ref={register}
              />
              <Form.Check
                id="filter-receipted"
                inline
                label={`접수(${state.count.receipted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIPTED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-scheduled"
                inline
                label={`수거지정(${state.count.pickupScheduled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED}
                ref={register}
              />
              <Form.Check
                id="filter-pickup-completed"
                inline
                label={`수거완료(${state.count.pickupCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_PICKUP_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-warehoused"
                inline
                label={`입고(${state.count.warehoused})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_WAREHOUSED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-allocated"
                inline
                label={`배송배차(${state.count.deliveryAllocated})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_ALLOCATED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-postponed"
                inline
                label={`배송연기(${state.count.postponed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-started"
                inline
                label={`배송출고(${state.count.deliveryStarted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_STARTED}
                ref={register}
              />
              <Form.Check
                id="filter-delivery-completed"
                inline
                label={`배송완료(${state.count.deliveryCompleted})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED}
                ref={register}
              />
              <Form.Check
                id="filter-accident"
                inline
                label={`사고(${state.count.accident})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_ACCIDENT}
                ref={register}
              />
              <Form.Check
                id="filter-canceled"
                inline
                label={`취소(${state.count.canceled})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_CANCELED}
                ref={register}
              />
              <Form.Check
                id="filter-typePickup"
                inline
                label={`픽업(${state.count.typePickup})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_PICKUP}
                ref={register}
              />
              <Form.Check
                id="filter-typeReturn"
                inline
                label={`반품(${state.count.typeReturn})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_TYPE_RETURN}
                ref={register}
              />
              <Form.Check
                id="filter-customCustomerPostalCode"
                inline
                label={`우편번호지역(${state.count.customCustomerPostalCode})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE
                }
                ref={register}
              />
              <Form.Check
                id="filter-senderAddressError"
                inline
                label={`송하인주소오류(${state.count.senderAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR}
                ref={register}
              />
              <Form.Check
                id="filter-senderAddressErrorCanceled"
                inline
                label={`송하인주소오류취소(${state.count.senderAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressError"
                inline
                label={`수하인주소오류(${state.count.receiverAddressError})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR
                }
                ref={register}
              />
              <Form.Check
                id="filter-receiverAddressErrorCanceled"
                inline
                label={`수하인주소오류취소(${state.count.receiverAddressErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-deliverySentBack"
                inline
                label={`반송완료(${state.count.deliverySentBack})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_SENT_BACK}
                ref={register}
              />
              <Form.Check
                id="filter-deliveryLost"
                inline
                label={`분실완료(${state.count.deliveryLost})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELIVERY_LOST}
                ref={register}
              />
              <Form.Check
                id="filter-mobileError"
                inline
                label={`연락처오류(${state.count.mobileError})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR}
                ref={register}
              />
              <Form.Check
                id="filter-mobileErrorCanceled"
                inline
                label={`연락처오류취소(${state.count.mobileErrorCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-delayedDeliveries"
                inline
                label={`미배송기록(${state.count.delayed})`}
                name="queryTypes"
                type="checkbox"
                value={DeliveriesStore.QUERY_DELIVERY_TYPE_DELAYED}
                ref={register}
              />
              <Form.Check
                id="filter-addressNotSupported"
                inline
                label={`배송불가지역(${state.count.addressNotSupported})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED
                }
                ref={register}
              />
              <Form.Check
                id="filter-addressNotSupportedCanceled"
                inline
                label={`배송불가지역취소(${state.count.addressNotSupportedCanceled})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED
                }
                ref={register}
              />
              <Form.Check
                id="filter-agencyAllocationKookmin"
                inline
                label={`배달접수(${state.count.agencyAllocationKookmin})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN
                }
                ref={register}
              />
              <Form.Check
                id="filter-agencyAllocationFailed"
                inline
                label={`배달접수실패(${state.count.agencyAllocationFailed})`}
                name="queryTypes"
                type="checkbox"
                value={
                  DeliveriesStore.QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED
                }
                ref={register}
              />
            </Form>
          </Col>
        </CheckBoxRow>
        <Row>
          <CounterContainer fetchTime={fetchTime} fetch={fetch} />
        </Row>
        <TableRow>
          <TableCol>
            <DeliveriesTable
              deliveries
              responsive
              bordered
              hover
              columns={columns}
              currentPage={state.query.page}
              data={state.deliveries}
              goToPage={handleGoToPage}
              nextPage={handleNextPage}
              onClickHeader={handleClickHeader}
              pageCount={state.pageCount}
              pageSize={state.query.pageSize}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </TableCol>
        </TableRow>
      </Container>
    </Wrap>
  );
});
