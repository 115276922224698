export class User {
  authority = 1;
  createdAt = null;
  etc = null;
  id = null;
  isDeleted = false;
  lastSignedAt = null;
  locked = false;
  realname = "";
  rider = null;
  riderId = null;
  type = null;
  updatedAt = null;
  username = "";

  constructor(user) {
    this.authority = user.authority;
    this.createdAt = user.createdAt;
    this.etc = user.etc;
    this.id = user.id;
    this.isDeleted = user.isDeleted;
    this.lastSignedAt = user.lastSignedAt;
    this.locked = user.locked;
    this.realname = user.realname;
    this.rider = user.rider;
    this.riderId = user.riderId;
    this.type = user.type;
    this.updatedAt = user.updatedAt;
    this.username = user.username;
  }

  get authorizedForAllTypes() {
    return !!this.etcObj?.authorizedForAllTypes || false;
  }

  get etcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  renderAuthority() {
    switch (this.authority) {
      case 1:
        return "수퍼관리자";
      case 2:
        return "부관리자";
      case 3:
        return "라이더";
      default:
        return "";
    }
  }

  renderLocked() {
    return this.locked ? "정지" : "정상";
  }
}
