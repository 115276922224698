import React, { Component } from "react";
import { ThemeProvider } from "emotion-theming";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { Global, css } from "@emotion/core";
import * as Sentry from "@sentry/react";

import {
  AddressStore,
  AgPostalCodesStore,
  AgPostalCodesByCorpStore,
  AlimtalkNoticeStore,
  AllocationGroupsStore,
  AllocationMatchesStore,
  AllocationsStore,
  ApiStore,
  AuthStore,
  BaseAddressesStore,
  BaseAddressesCorrectionsStore,
  CorpUploadFailedStore,
  CorpUsersStore,
  CsesStore,
  CustomDongsStore,
  ErrorStore,
  DeletedCorpUsersStore,
  DeletedRidersStore,
  DeliveriesStore,
  DeliveriesMonthlyStore,
  DeliveriesPickupsStore,
  DeliverieskurlyStore,
  DeliveriesMisclassificationStore,
  DeliveriesReturnsStore,
  DeliveriesSummaryStore,
  DongsStore,
  DongsNotSupportedStore,
  DownloadsStore,
  FailedScanStore,
  HanjinStore,
  HellovisionStore,
  HolidaysStore,
  ModalStore,
  LoadingStore,
  LogsStore,
  ReallocatedDeliveriesStore,
  ReallocationLogsStore,
  ReviewsStore,
  RiderGroupsStore,
  RiderNoticesStore,
  RidersStore,
  SessionLogsStore,
  SigungusStore,
  SpotsStore,
  StatisticsStore,
  TranslateStore,
  UsersStore,
} from "./store";
import theme from "./theme";

import Router from "./Router";

import "bootstrap/dist/css/bootstrap.min.css";

class ErrorBarrier extends Component {
  componentDidCatch(e) {
    if (process.env.REACT_APP_ENV === "production") {
      Sentry.captureException(e);
    }
    throw new Error(e);
  }

  render() {
    return <>{this.props.children}</>;
  }
}

const GlobalStyle = css`
  body {
    font-size: 14px;
  }

  *,
  :after,
  :before {
    box-sizing: border-box;
  }

  .col-form-label {
    font-size: 16px;
  }

  .wysiwyg-editor {
    border: 1px solid #eee;
  }
`;

const AppProvider = ({ contexts, children }) =>
  contexts.reduce(
    (prev, context) =>
      React.createElement(context, {
        children: prev,
      }),
    children,
  );

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBarrier>
        <Global styles={GlobalStyle} />
        <CookiesProvider>
          <AppProvider
            contexts={[
              AddressStore.Provider,
              AgPostalCodesStore.Provider,
              AgPostalCodesByCorpStore.Provider,
              AlimtalkNoticeStore.Provider,
              AllocationGroupsStore.Provider,
              AllocationMatchesStore.Provider,
              AllocationsStore.Provider,
              ApiStore.Provider,
              AuthStore.Provider,
              BaseAddressesStore.Provider,
              BaseAddressesCorrectionsStore.Provider,
              CorpUploadFailedStore.Provider,
              CorpUsersStore.Provider,
              CsesStore.Provider,
              CustomDongsStore.Provider,
              ErrorStore.Provider,
              DeletedCorpUsersStore.Provider,
              DeletedRidersStore.Provider,
              DeliveriesStore.Provider,
              DeliveriesMonthlyStore.Provider,
              DeliveriesPickupsStore.Provider,
              DeliverieskurlyStore.Provider,
              DeliveriesMisclassificationStore.Provider,
              DeliveriesReturnsStore.Provider,
              DeliveriesSummaryStore.Provider,
              DongsStore.Provider,
              DongsNotSupportedStore.Provider,
              DownloadsStore.Provider,
              FailedScanStore.Provider,
              HanjinStore.Provider,
              HellovisionStore.Provider,
              HolidaysStore.Provider,
              ModalStore.Provider,
              LoadingStore.Provider,
              LogsStore.Provider,
              ReallocatedDeliveriesStore.Provider,
              ReallocationLogsStore.Provider,
              ReviewsStore.Provider,
              RiderGroupsStore.Provider,
              RiderNoticesStore.Provider,
              RidersStore.Provider,
              SessionLogsStore.Provider,
              SigungusStore.Provider,
              SpotsStore.Provider,
              StatisticsStore.Provider,
              TranslateStore.Provider,
              UsersStore.Provider,
            ]}
          >
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </AppProvider>
        </CookiesProvider>
      </ErrorBarrier>
    </ThemeProvider>
  );
}

export default App;
