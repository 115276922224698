import React, { memo, useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { EditBaseAddressModal, CreateBaseAddressModal } from "@/Modals";
import { useBaseAddressesStore, useModalStore } from "@/store/hooks";

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledForm = styled(Form)`
  flex: 1;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 0;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BaseAddressesTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const UpdateColumn = memo(({ baseAddressMemo, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  const handleClickUpdate = useCallback(async () => {
    openModal(
      <EditBaseAddressModal
        key="edit-base-address-modal"
        baseAddressMemo={baseAddressMemo}
        onUpdate={onUpdate}
      />,
    );
  }, [onUpdate, baseAddressMemo, openModal]);

  return <SmallButton onClick={handleClickUpdate}>수정</SmallButton>;
});

const DeleteColumn = memo(({ id, onDelete = _noop }) => {
  const { ...actions } = useBaseAddressesStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm(`정말 삭제하시겠습니까?`)) {
      try {
        await actions.deleteMemo(id);
        onDelete();
      } catch (e) {
        window.alert(`삭제에 실패했습니다: ${e.message}`);
      }
      onDelete();
    }
  }, [actions.deleteMemo, onDelete, id]);

  return (
    <SmallButton onClick={handleClickDelete} variant="secondary">
      삭제
    </SmallButton>
  );
});

export default () => {
  const { state, ...actions } = useBaseAddressesStore();
  const { openModal } = useModalStore();

  const { handleSubmit } = useForm();

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(() => {
    if (state.query.address || state.query.addressRoad) {
      actions.fetchAll();
    }
  }, [actions.fetchAll, state.query]);

  const handleChange = useCallback(
    (e) => {
      if (e.target.name === "deleted") {
        actions.setQuery({
          [e.target.name]: !state.query.deleted,
        });
      } else {
        actions.setQuery({
          [e.target.name]: e.target.value,
        });
      }
    },
    [actions.setQuery, state.query.deleted],
  );

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateBaseAddressModal
        key="create-base-address-modal"
        onCreate={fetchAll}
      />,
    );
  }, [openModal, fetchAll]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(() => {
    actions.setQuery({ page: 1 });
    fetchAll();
  }, [actions.setQuery, fetchAll]);

  const columns = useMemo(() => [
    {
      Header: "지번 주소",
      accessor: "address",
      width: 200,
    },
    {
      Header: "도로명 주소",
      accessor: "addressRoad",
      width: 200,
    },
    {
      Header: "빌딩 동 번호",
      accessor: "buildingDongNumber",
      width: 100,
    },
    {
      Header: "정보",
      accessor: "memo",
      width: 300,
    },
    {
      Header: "입력라이더",
      accessor: "riderName",
      width: 100,
    },
    {
      id: "edit",
      Header: "수정",
      accessor: (row) =>
        !row.deleted ? (
          <UpdateColumn baseAddressMemo={row} onUpdate={fetchAll} />
        ) : (
          ""
        ),
      selectable: false,
      width: 100,
    },
    {
      id: "delete",
      Header: "삭제",
      accessor: (row) =>
        !row.deleted ? (
          <DeleteColumn id={row.id} onDelete={fetchAll} />
        ) : (
          "삭제됨"
        ),
      selectable: false,
      width: 100,
    },
  ]);

  return (
    <Container>
      <Title>주소 정보 관리</Title>
      <SearchRow>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <StyledCol md={3}>
              <FormGroup>
                <Form.Control
                  name="address"
                  onChange={handleChange}
                  placeholder="지번 주소"
                  value={state.query.address}
                />
              </FormGroup>
            </StyledCol>
            <StyledCol md={3}>
              <FormGroup>
                <Form.Control
                  name="addressRoad"
                  onChange={handleChange}
                  placeholder="도로명 주소"
                  value={state.query.addressRoad}
                />
              </FormGroup>
            </StyledCol>
            <StyledCol md={2}>
              <FormGroup>
                <Form.Check
                  id="checkbox-deleted"
                  inline
                  label="삭제 정보 포함"
                  name="deleted"
                  type="checkbox"
                  onChange={handleChange}
                  checked={state.query.deleted}
                />
              </FormGroup>
            </StyledCol>
            <StyledCol md={3}>
              <Button type="submit">검색하기</Button>
            </StyledCol>
          </StyledRow>
        </StyledForm>
        <Button type="button" onClick={handleClickCreate}>
          주소정보 추가
        </Button>
      </SearchRow>

      <BaseAddressesTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.baseAddressMemos}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
