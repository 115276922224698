import Error406 from "@/pages/Error406";
import Login from "@/pages/Login";
import Address from "@/pages/Address";
import AddressSoojione from "@/pages/AddressSoojione";
import AgPostalCodes from "@/pages/AgPostalCodes";
import AgPostalCodesByCorp from "@/pages/AgPostalCodesByCorp";
import AlimtalkNotice from "@/pages/AlimtalkNotice";
import Allocations from "@/pages/Allocations";
import AllocationGroups from "@/pages/AllocationGroups";
import AllocationMatches from "@/pages/AllocationMatches";
import BaseAddresses from "@/pages/BaseAddresses";
import BaseAddressesCorrections from "@/pages/BaseAddressesCorrections";
import CorpUploadFailed from "@/pages/CorpUploadFailed";
import CorpScheduler from "@/pages/CorpScheduler";
import CorpUsers from "@/pages/CorpUsers";
import Cses from "@/pages/Cses";
import CustomDongs from "@/pages/CustomDongs";
import DeletedCorpUsers from "@/pages/DeletedCorpUsers";
import DeletedRiders from "@/pages/DeletedRiders";
import Deliveries from "@/pages/Deliveries";
import DeliveriesMonthly from "@/pages/DeliveriesMonthly";
import DeliveriesPickups from "@/pages/DeliveriesPickups";
import DeliveriesKurly from "@/pages/DeliveriesKurly";
import DeliveriesMisclassification from "@/pages/DeliveriesMisclassification";
import DeliveriesReturns from "@/pages/DeliveriesReturns";
import DeliveriesSummary from "@/pages/DeliveriesSummary";
import Dongs from "@/pages/Dongs";
import DongsNotSupported from "@/pages/DongsNotSupported";
import Downloads from "@/pages/Downloads";
import DownloadsHidden from "@/pages/DownloadsHidden";
import FailedScan from "./pages/FailedScan";
import Hanjin from "@/pages/Hanjin";
import Hellovision from "@/pages/Hellovision";
import Holidays from "@/pages/Holidays";
import Logs from "@/pages/Logs";
import Reviews from "./pages/Reviews";
import ReallocatedDeliveries from "./pages/ReallocatedDeliveries";
import ReallocationLogs from "./pages/ReallocationLogs";
import Returns from "@/pages/Returns";
import RiderNotices from "@/pages/RiderNotices";
import RiderGroups from "@/pages/RiderGroups";
import Riders from "@/pages/Riders";
import RidersLocation from "@/pages/RidersLocation";
import SessionLogs from "@/pages/SessionLogs";
import Spots from "@/pages/Spots";
import SpotsRequested from "@/pages/SpotsRequested";
import Statistics from "@/pages/Statistics";
import Translate from "@/pages/Translate";
import Users from "@/pages/Users";
import UsersWithRiders from "@/pages/UsersWithRiders";
import UsersPassword from "@/pages/UsersPassword";

export const INDEX_PUBLIC_ROUTE_PATHNAME = "/";
export const INDEX_PRIVATE_ROUTE_PATHNAME = "/deliveries";
export const INDEX_RIDER_ROUTE_PATHNAME = "/";

export default [
  {
    component: Address,
    exact: true,
    fluid: true,
    name: "Address",
    nav: true,
    path: "/address",
    private: true
  },
  {
    component: AddressSoojione,
    exact: true,
    fluid: true,
    name: "AddressSoojione",
    nav: true,
    path: "/address-soojione",
    private: true
  },
  {
    component: AgPostalCodes,
    exact: true,
    fluid: true,
    name: "AgPostalCodes",
    nav: true,
    path: "/ag-postal-codes",
    private: true
  },
  {
    component: AgPostalCodesByCorp,
    exact: true,
    fluid: true,
    name: "AgPostalCodesByCorp",
    nav: true,
    path: "/ag-postal-codes-by-corp",
    private: true
  },
  {
    component: AlimtalkNotice,
    exact: true,
    fluid: true,
    name: "AlimtalkNotice",
    nav: true,
    path: "/alimtalk-notice",
    private: true
  },
  {
    component: Allocations,
    exact: true,
    fluid: true,
    name: "Allocations",
    nav: true,
    path: "/allocations",
    private: true,
    rider: true
  },
  {
    component: AllocationGroups,
    exact: true,
    fluid: true,
    name: "AllocationGroups",
    nav: true,
    path: "/allocation-groups",
    private: true
  },
  {
    component: AllocationMatches,
    exact: true,
    fluid: true,
    name: "AllocationMatches",
    nav: true,
    path: "/allocation-matches",
    private: true
  },
  {
    component: BaseAddresses,
    exact: true,
    fluid: true,
    name: "BaseAddresses",
    nav: true,
    path: "/base-addresses",
    private: true
  },
  {
    component: BaseAddressesCorrections,
    exact: true,
    fluid: true,
    name: "BaseAddressesCorrections",
    nav: true,
    path: "/base-addresses-corrections",
    private: true
  },
  {
    component: CorpScheduler,
    exact: true,
    fluid: true,
    name: "CorpScheduler",
    nav: true,
    path: "/corp-scheduler",
    private: true
  },
  {
    component: CorpUploadFailed,
    exact: true,
    fluid: true,
    name: "CorpUploadFailed",
    nav: true,
    path: "/corp-upload-failed",
    private: true
  },
  {
    component: CorpUsers,
    exact: true,
    fluid: true,
    name: "CorpUsers",
    nav: true,
    path: "/corp-users",
    private: true
  },
  {
    component: Cses,
    exact: true,
    fluid: true,
    name: "Cses",
    nav: true,
    path: "/cses",
    private: true
  },
  {
    component: CustomDongs,
    exact: true,
    fluid: true,
    name: "CustomDongs",
    nav: true,
    path: "/custom-dongs",
    private: true
  },
  {
    component: DeletedCorpUsers,
    exact: true,
    fluid: true,
    name: "DeletedCorpUsers",
    nav: true,
    path: "/deleted-corp-users",
    private: true
  },
  {
    component: DeletedRiders,
    exact: true,
    fluid: true,
    name: "DeletedRiders",
    nav: true,
    path: "/deleted-riders",
    private: true
  },
  {
    component: Deliveries,
    exact: true,
    fluid: true,
    name: "Deliveries",
    nav: true,
    path: "/deliveries",
    private: true
  },
  {
    component: DeliveriesMonthly,
    exact: true,
    fluid: true,
    name: "DeliveriesMonthly",
    nav: true,
    path: "/deliveries-monthly",
    private: true
  },
  {
    component: DeliveriesKurly,
    exact: true,
    fluid: true,
    name: "DeliveriesKurly",
    nav: true,
    path: "/deliveries-kurly",
    private: true
  },
  {
    component: DeliveriesMisclassification,
    exact: true,
    fluid: true,
    name: "DeliveriesMisclassification",
    nav: true,
    path: "/deliveries-misclassification",
    private: true
  },
  {
    component: DeliveriesReturns,
    exact: true,
    fluid: true,
    name: "DeliveriesReturns",
    nav: true,
    path: "/deliveries-returns",
    private: true
  },
  {
    component: DeliveriesPickups,
    exact: true,
    fluid: true,
    name: "DeliveriesPickups",
    nav: true,
    path: "/deliveries-pickups",
    private: true
  },
  {
    component: DeliveriesSummary,
    exact: true,
    fluid: true,
    name: "DeliveriesSummary",
    nav: true,
    path: "/deliveries-summary",
    private: true
  },
  {
    component: Dongs,
    exact: true,
    fluid: true,
    name: "Dongs",
    nav: true,
    path: "/dongs",
    private: true
  },
  {
    component: DongsNotSupported,
    exact: true,
    fluid: true,
    name: "DongsNotSupported",
    nav: true,
    path: "/dongs-not-supported",
    private: true
  },
  {
    component: Downloads,
    exact: true,
    fluid: true,
    name: "Downloads",
    nav: true,
    path: "/downloads",
    private: true
  },
  {
    component: DownloadsHidden,
    exact: true,
    fluid: true,
    name: "DownloadsHidden",
    nav: true,
    path: "/downloads/hidden",
    private: true
  },
  {
    component: Error406,
    exact: true,
    name: "Error406",
    path: "/error-406"
  },
  {
    component: FailedScan,
    exact: true,
    fluid: true,
    name: "FailedScan",
    nav: true,
    path: "/failed-scan",
    private: true
  },
  {
    component: Hanjin,
    exact: true,
    fluid: true,
    name: "Hanjin",
    nav: true,
    path: "/hanjin",
    private: true
  },
  {
    component: Hellovision,
    exact: true,
    fluid: true,
    name: "Hellovision",
    nav: true,
    path: "/hellovision",
    private: true
  },
  {
    component: Holidays,
    exact: true,
    fluid: true,
    name: "Holidays",
    nav: true,
    path: "/holidays",
    private: true
  },
  {
    component: Login,
    exact: true,
    name: "Login",
    path: "/"
  },
  {
    component: Login,
    exact: true,
    name: "Login-cellphone",
    path: "/login"
  },
  {
    component: Logs,
    exact: true,
    fluid: true,
    name: "Logs",
    nav: true,
    path: "/logs",
    private: true
  },
  {
    component: Reviews,
    exact: true,
    fluid: true,
    name: "Reviews",
    nav: true,
    path: "/reviews",
    private: true
  },
  {
    component: ReallocatedDeliveries,
    exact: true,
    fluid: true,
    name: "ReallocatedDeliveries",
    nav: true,
    path: "/reallocated-deliveries",
    private: true
  },
  {
    component: ReallocationLogs,
    exact: true,
    fluid: true,
    name: "ReallocationLogs",
    nav: true,
    path: "/reallocation-logs",
    private: true
  },
  {
    component: Returns,
    exact: true,
    fluid: true,
    name: "Returns",
    nav: true,
    path: "/returns",
    private: true
  },
  {
    component: RiderGroups,
    exact: true,
    fluid: true,
    name: "RiderGroups",
    nav: true,
    path: "/rider-groups",
    private: true
  },
  {
    component: RiderNotices,
    exact: true,
    fluid: true,
    name: "RiderNotices",
    nav: true,
    path: "/rider-notices",
    private: true
  },
  {
    component: Riders,
    exact: true,
    fluid: true,
    name: "Riders",
    nav: true,
    path: "/riders",
    private: true
  },
  {
    component: RidersLocation,
    exact: true,
    fluid: true,
    name: "RidersLocation",
    nav: true,
    path: "/riders-location",
    private: true
  },
  {
    component: SessionLogs,
    exact: true,
    fluid: true,
    name: "SessionLogs",
    nav: true,
    path: "/session-logs",
    private: true
  },
  {
    component: Spots,
    exact: true,
    fluid: true,
    name: "Spots",
    nav: true,
    path: "/spots",
    private: true
  },
  {
    component: SpotsRequested,
    exact: true,
    fluid: true,
    name: "RidersLocation",
    nav: true,
    path: "/spots-requested",
    private: true
  },
  {
    component: Statistics,
    exact: true,
    fluid: true,
    name: "Statistics",
    nav: true,
    path: "/statistics",
    private: true
  },
  {
    component: Translate,
    exact: true,
    fluid: true,
    name: "Translate",
    nav: true,
    path: "/translate",
    private: true
  },
  {
    component: Users,
    exact: true,
    fluid: true,
    name: "Users",
    nav: true,
    path: "/users",
    private: true
  },
  {
    component: UsersPassword,
    exact: true,
    fluid: true,
    name: "UsersPassword",
    nav: false,
    path: "/users/password",
    private: true
  },
  {
    component: UsersWithRiders,
    exact: true,
    fluid: true,
    name: "UsersWithRiders",
    nav: true,
    path: "/users-with-riders",
    private: true
  }
];
