import React, { useState, useEffect } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import SpotExcelSchemeForm from "@/forms/SpotExcelSchemeForm";
import { useModalStore, useSpotsStore } from "@/store/hooks";

const INITIAL_SCHEME = {
  type: "유형",
  customerName: "수하인명",
  customerMobile: "수하인연락처",
  customerMobile2: "",
  customerAddress: "수하인주소",
  customerAddressDetail: "수하인상세주소",
  receiverAddressPostalCode: "우편번호",
  prepaid: "선불",
  productName: "제품명",
  productCategory: "제품종류",
  productPrice: "제품가격",
  productCount: "수량",
  memoFromCustomer: "고객전달사항",
  frontdoorPassword: "공동현관비밀번호",
  orderIdFromCorp: "기업고유번호",
  etc1: "기타사항1",
  etc2: "기타사항2",
  etc3: "기타사항3",
  corpNumber: "",
};

const Title = styled.h3`
  margin: 0;
`;

export default ({ id, onUpdate = _noop } = {}) => {
  const [excelScheme, setExcelScheme] = useState(INITIAL_SCHEME);
  const [loading, setLoading] = useState(true);

  const { ...actions } = useSpotsStore();
  const { closeModal } = useModalStore();

  useEffect(() => {
    actions.fetchByIdOnTheFly(id).then((spot) => {
      try {
        setExcelScheme(JSON.parse(spot.excelScheme || excelScheme));
      } catch (_e) {}

      setLoading(false);
    });
  }, []);

  const onSubmit = async (excelScheme) => {
    if (Object.values(excelScheme).filter((val) => !!val).length === 0) {
      window.alert("대량접수포맷이 유효하지 않아 등록이 취소되었습니다.");
      closeModal();
      return;
    }

    try {
      await actions.updateByIdOnTheFly(id, {
        excelScheme,
      });
      window.alert("대량접수포맷이 성공적으로 수정되었습니다.");
      closeModal();
      onUpdate();
    } catch (e) {
      window.alert(
        `대량접수포맷 수정이 실패하였습니다.\n에러메시지: ${e.message}`
      );
    }
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>대량접수포맷 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <SpotExcelSchemeForm
          onSubmit={onSubmit}
          initialExcelScheme={excelScheme}
          loading={loading}
        />
      </Modal.Body>
    </Modal>
  );
};
