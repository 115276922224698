import React, { useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { useAuthStore, useUsersStore, useModalStore } from "@/store/hooks";
import {
  CreateUserModal,
  EditUserResetPasswordModal,
  EditUserPasswordModal,
  EditUserModal,
} from "@/Modals";
import { formatDate, getDateByTimeZone } from "@/lib/date";

const ButtonsRow = styled(Row)`
  padding: 10px 0;
`;

const UsersTable = styled(Table)``;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const EditColumn = ({ id, onUpdate }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditUserModal key="edit-user-modal" id={id} onUpdate={onUpdate} />,
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

const ResetPasswordColumn = ({ id, onUpdate }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditUserResetPasswordModal
        key="edit-user-reset-password-modal"
        id={id}
        onUpdate={onUpdate}
      />,
    );
  }

  return <SmallButton onClick={handleClickEdit}>변경</SmallButton>;
};

const LockColumn = ({ id, locked, onUpdate }) => {
  const { ...actions } = useUsersStore();

  const handleToggleLocked = useCallback(async () => {
    if (id) {
      await actions
        .lock(id, { locked: !locked })
        .then(() => {
          onUpdate();
        })
        .catch((e) => {
          window.alert(e.message);
        });
    }
  }, [actions.lock, id, locked]);

  return (
    <SmallButton
      onClick={handleToggleLocked}
      variant={locked ? "outline-secondary" : "secondary"}
    >
      {locked ? "이용정지" : "정상"}
    </SmallButton>
  );
};

const DeleteColumn = ({ id, onDelete = _noop }) => {
  const { ...actions } = useUsersStore();

  const handleClickDelete = useCallback(() => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      actions.deleteUser(id);
      onDelete();
    }
  }, [actions, onDelete]);

  return (
    <SmallButton variant="danger" onClick={handleClickDelete}>
      삭제
    </SmallButton>
  );
};

export default () => {
  const { state: authState } = useAuthStore();
  const { state, ...actions } = useUsersStore();
  const { openModal } = useModalStore();
  const { handleSubmit } = useForm();

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(() => {
    try {
      actions.fetchAll({ withRiderManager: "0" });
    } catch (e) {
      window.alert(`목록을 불러올 수 없습니다.: ${e.message}`);
      console.log(e);
    }
  }, [actions.fetchAll]);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({ [e.target.name]: e.target.value });
    },
    [actions.setQuery],
  );

  function handleClickCreate() {
    openModal(
      <CreateUserModal key="create-user-modal" onCreate={handleCreate} />,
    );
  }

  const handleClickPassword = useCallback(() => {
    openModal(
      <EditUserPasswordModal
        key="edit-user-password-modal"
        onUpdate={handleUpdate}
      />,
    );
  }, []);

  const handleCreate = useCallback(() => {
    fetchAll();
  }, []);

  const handleDelete = useCallback(() => {
    fetchAll();
  }, []);

  const handleUpdate = useCallback(() => {
    fetchAll();
  }, []);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(() => {
    fetchAll();
  }, []);

  const columnsArray = [
    {
      Header: "id",
      accessor: "id",
      width: 80,
    },
    {
      Header: "사용자 ID",
      accessor: "username",
    },
    {
      Header: "사용자 이름",
      accessor: "realname",
      width: 130,
    },
    {
      Header: "권한",
      accessor: (row) => row.renderAuthority(),
      width: 120,
    },
    {
      Header: "최근 로그인",
      accessor: (row) =>
        row.lastSignedAt &&
        formatDate(getDateByTimeZone(row.lastSignedAt), "yyyy/MM/dd HH:mm"),
      width: 160,
    },
  ];

  if (authState.user.authority === 1) {
    columnsArray.push(
      {
        id: "edit",
        Header: "수정",
        accessor: (row) => <EditColumn id={row.id} onUpdate={handleUpdate} />,
        selectable: false,
        width: 100,
      },
      {
        id: "password",
        Header: "비밀번호 리셋",
        accessor: (row) => (
          <ResetPasswordColumn id={row.id} onUpdate={handleUpdate} />
        ),
        selectable: false,
        width: 100,
      },
      {
        id: "lock",
        Header: "잠금여부",
        accessor: (row) => (
          <LockColumn id={row.id} locked={row.locked} onUpdate={handleUpdate} />
        ),
        selectable: false,
        width: 100,
      },
      {
        id: "delete",
        Header: "삭제",
        accessor: (row) => <DeleteColumn id={row.id} onDelete={handleDelete} />,
        selectable: false,
        width: 100,
      },
    );
  }

  const columns = useMemo(() => columnsArray, []);

  return (
    <Container>
      <Title>관리자 관리</Title>
      {authState.user.authority === 1 && (
        <ButtonsRow>
          <Col>
            <Button onClick={handleClickCreate}>관리자 등록</Button>
          </Col>
        </ButtonsRow>
      )}
      {authState.user.authority === 2 && (
        <ButtonsRow>
          <Col>
            <Button onClick={handleClickPassword}>내 비밀번호 수정</Button>
          </Col>
        </ButtonsRow>
      )}
      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="text"
                    onChange={handleChange}
                    placeholder="ID, 사용자 이름"
                    value={state.query.text}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 2 }}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>
      <Row>
        <Col>
          <UsersTable
            responsive
            bordered
            columns={columns}
            currentPage={state.query.page}
            data={state.users}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.query.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
