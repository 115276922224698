export const REASON_ABSENCE = "REASON_ABSENCE";
export const REASON_REQUEST = "REASON_REQUEST";
export const REASON_ADDRESS_ERROR = "REASON_ADDRESS_ERROR";
export const REASON_MOBILE_ERROR = "REASON_MOBILE_ERROR";
export const REASON_NO_SPACE = "REASON_NO_SPACE";
export const REASON_HEAVY_RAIN = "REASON_HEAVY_RAIN";
export const REASON_HEAVY_SNOW = "REASON_HEAVY_SNOW";
export const REASON_VEHICLE_FAULT = "REASON_VEHICLE_FAULT";
export const REASON_NOT_TODAY = "REASON_NOT_TODAY";
export const REASON_MISCLASSIFICATION = "REASON_MISCLASSIFICATION";
export const REASON_ETC = "REASON_ETC";

export const ALLOCATION_MATCH_TYPES = {
  PICKUP: "1",
  DELIVERY: "2"
};

export const DELIVERY_STATUS_TYPES = {
  RECEIPTED: 0,
  PICKUP_SCHEDULED: 1,
  PICKUP_COMPLETED: 2,
  WAREHOUSED: 3,
  DELIVERY_STARTED: 4,
  DELIVERY_COMPLETED: 5,
  DELIVERY_SENT_BACK: 6,
  DELIVERY_LOST: 7,
  DELIVERY_RIDER_ALLOCATED: 8,
  ADDRESS_NOT_SUPPORTED: 9,
  ACCIDENT: 10,
  CANCELED: 11,
  DELIVERY_POSTPONED: 12,
  TAKE_OVER: 14,
  MAIN_LINE_GET_OFF: 15,
  AGENCY_ALLOCATED: 16,
  AGENCY_ALLOCATION_FAILED: 17,
  AGENCY_CANCELED: 18,
  AGENCY_DELIVERY_RIDER_ALLOCATED: 19,
  AGENCY_DELIVERY_RIDER_ALLOCATION_CANCELED: 20
};

export const CORP_USER_NAMES = {
  CJ: "cjlogistics",
  EMART: "emart24admin",
  OLIVE: "cjoyapi",
  OLIVE_STARTED: "cjoyapi_started",
  OLIVE_COMPLETED: "cjoyapi_completed",
  HANJIN: "hanjinapi",
  DRX: "drx"
};

export const renderCorpUserTitle = corpUserName => {
  switch (corpUserName) {
    case CORP_USER_NAMES.CJ:
      return "CJ대한통운";
    case CORP_USER_NAMES.EMART:
      return "이마트24";
    case CORP_USER_NAMES.OLIVE:
      return "올리브영";
    case CORP_USER_NAMES.HANJIN:
      return "한진";
    case CORP_USER_NAMES.DRX:
      return "디알엑스";
    default:
      return "";
  }
};

export const DELAY_REASONS = [
  {
    text: "수령인 부재",
    code: REASON_ABSENCE
  },
  {
    text: "고객지정일(수령인 요청)",
    code: REASON_REQUEST
  },
  {
    text: "주소지 오류",
    code: REASON_ADDRESS_ERROR
  },
  {
    text: "연락처 오류",
    code: REASON_MOBILE_ERROR
  },
  {
    text: "출입불가 및 보관장소없음",
    code: REASON_NO_SPACE
  },
  {
    text: "오분류",
    code: REASON_MISCLASSIFICATION
  },
  {
    text: "기상악화(폭우)",
    code: REASON_HEAVY_RAIN
  },
  {
    text: "기상악화(폭설)",
    code: REASON_HEAVY_SNOW
  },
  {
    text: "차량사고(차량고장)",
    code: REASON_VEHICLE_FAULT
  },
  {
    text: "순차배송예정(당일배송불가)",
    code: REASON_NOT_TODAY
  },
  {
    text: "기타",
    code: REASON_ETC
  }
];
