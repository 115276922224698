import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import dfSubDays from "date-fns/subDays";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const ALLOCATE_TYPES = {
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
};

export const CONSTANTS = {
  SET_COUNT: "SET_COUNT",
  SET_QUERY: "SET_QUERY",
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALL_DELAYED: "FETCH_ALL_DELAYED",
  RESET: "RESET",
  RESET_QUERY: "RESET_QUERY",
};

export const QUERY_DATE_TYPE_RECEIPT_DATE = "RECEIPT_DATE";
export const QUERY_DATE_TYPE_DELIVERY_ALLOCATION_DATE =
  "DELIVERY_ALLOCATION_DATE";
export const QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE = "PICKUP_COMPLETED_DATE";
export const QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE =
  "DELIVERY_COMPLETED_DATE";

export const QUERY_DELIVERY_TYPE_ALL = "ALL";
export const QUERY_DELIVERY_TYPE_RECEIPTED = "RECEIPTED";
export const QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED = "PICKUP_SCHEDULED";
export const QUERY_DELIVERY_TYPE_PICKUP_COMPLETED = "PICKUP_COMPLETED";
export const QUERY_DELIVERY_TYPE_WAREHOUSED = "WAREHOUSED";
export const QUERY_DELIVERY_TYPE_DELIVERY_ALLOCATED = "DELIVERY_ALLOCATED";
export const QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED = "DELIVERY_POSTPONED";
export const QUERY_DELIVERY_TYPE_DELIVERY_STARTED = "DELIVERY_STARTED";
export const QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED = "DELIVERY_COMPLETED";
export const QUERY_DELIVERY_TYPE_ACCIDENT = "ACCIDENT";
export const QUERY_DELIVERY_TYPE_CANCELED = "CANCELED";
export const QUERY_DELIVERY_TYPE_TYPE_PICKUP = "TYPE_PICKUP";
export const QUERY_DELIVERY_TYPE_TYPE_RETURN = "TYPE_RETURN";
export const QUERY_DELIVERY_CUSTOM_CUSTOMER_POSTAL_CODE =
  "CUSTOM_CUSTOMER_POSTAL_CODE";
export const QUERY_DELIVERY_TYPE_ADDRESS_ERROR = "ADDRESS_ERROR";
export const QUERY_DELIVERY_TYPE_ADDRESS_ERROR_CANCELED =
  "ADDRESS_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR = "SENDER_ADDRESS_ERROR";
export const QUERY_DELIVERY_TYPE_SENDER_ADDRESS_ERROR_CANCELED =
  "SENDER_ADDRESS_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR =
  "RECEIVER_ADDRESS_ERROR";
export const QUERY_DELIVERY_TYPE_RECEIVER_ADDRESS_ERROR_CANCELED =
  "RECEIVER_ADDRESS_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_DELIVERY_SENT_BACK = "DELIVERY_SENT_BACK";
export const QUERY_DELIVERY_TYPE_DELIVERY_LOST = "DELIVERY_LOST";
export const QUERY_DELIVERY_TYPE_MOBILE_ERROR = "MOBILE_ERROR";
export const QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED =
  "MOBILE_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_DELAYED = "DELAYED";
export const QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED =
  "ADDRESS_NOT_SUPPORTED";
export const QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED =
  "ADDRESS_NOT_SUPPORTED_CANCELED";
export const QUERY_BALANCE_ACCOUNT = "BALANCE_ACCOUNT";
export const QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN =
  "AGENCY_ALLOCATION_KOOKMIN"; // 배달 대행(국민배달)
export const QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_FAILED =
  "AGENCY_ALLOCATION_FAILED"; // 배달 대행 실패(국민배달)

export const AGENCY_KOOKMIN_USERNAME = "kookminfsd";
export const AGENCY_KOOKMIN_CORPTITLE = "국민fsd";

const zonedDateYesterday = dfSubDays(getDateByTimeZone(), 1);
const zonedDateToday = getDateByTimeZone();
const initDateFrom = formatDate(zonedDateYesterday);
const initDateTo = formatDate(zonedDateToday);

const INITIAL_QUERY = {
  allocationGroupName: "",
  bookId: "",
  corpUserName: "",
  dateType: QUERY_DATE_TYPE_RECEIPT_DATE,
  dateFrom: initDateFrom,
  dateTo: initDateTo,
  orderBy: "",
  orderIdFromCorp: "",
  orderingNumberByDeliveryRiderId: "",
  page: 1,
  pageSize: 100,
  receiverAddressRoad: "",
  receiverDong: "",
  receiverMobile: "",
  receiverName: "",
  region: "",
  rider: "",
  pickupRiderName: "",
  deliveryRiderName: "",
  senderDong: "",
  spotName: "",
  senderName: "",
  senderMobile: "",
  queryTypes: [QUERY_DELIVERY_TYPE_ALL],
  types: "1;2;3",
};

const INITIAL_STATE = {
  count: {
    all: 0,
    receipted: 0,
    pickupScheduled: 0,
    pickupCompleted: 0,
    warehoused: 0,
    deliveryAllocated: 0,
    postponed: 0,
    deliveryStarted: 0,
    deliveryCompleted: 0,
    accident: 0,
    canceled: 0,
    typePickup: 0,
    typeReturn: 0,
    customCustomerPostalCode: 0,
    senderAddressError: 0,
    senderAddressErrorCanceled: 0,
    receiverAddressError: 0,
    receiverAddressErrorCanceled: 0,
    deliverySentBack: 0,
    deliveryLost: 0,
    mobileError: 0,
    mobileErrorCanceled: 0,
    delayed: 0,
    addressNotSupported: 0,
    addressNotSupportedCanceled: 0,
    agencyAllocationKookmin: 0,
    agencyAllocationFailed: 0,
  },
  deliveries: [],
  deliveriesWholeData: [],
  pageCount: 1,
  query: INITIAL_QUERY,
  reset: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveriesWholeData: action.deliveriesWholeData,
        deliveries: action.deliveries,
        pageCount: action.pageCount,
      };
    case CONSTANTS.FETCH_ALL_DELAYED:
      return {
        ...state,
        deliveries: action.deliveries,
      };
    case CONSTANTS.RESET:
      return {
        ...state,
        reset: action.reset,
      };
    case CONSTANTS.RESET_QUERY:
      return {
        ...state,
        query: INITIAL_QUERY,
        reset: true,
      };
    case CONSTANTS.SET_COUNT:
      return {
        ...state,
        count: {
          ...action.count,
          delayed: action.countDelayed,
          all:
            (action.count.receipted || 0) +
            (action.count.pickupScheduled || 0) +
            (action.count.pickupCompleted || 0) +
            (action.count.warehoused || 0) +
            (action.count.deliveryAllocated || 0) +
            (action.count.postponed || 0) +
            (action.count.deliveryStarted || 0) +
            (action.count.deliveryCompleted || 0),
          // action.count.customCustomerPostalCode,
        },
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

const makeQueryStringObj = (query = INITIAL_QUERY) => {
  const queryTypes =
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_ALL) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_PICKUP) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_RETURN) ||
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN)
      ? ""
      : query.queryTypes.join(";");

  let types = query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_PICKUP)
    ? "2"
    : query.queryTypes.includes(QUERY_DELIVERY_TYPE_TYPE_RETURN)
    ? "3"
    : query.types;

  let spotNames = "";
  let spotName = "";

  if (query.spotName?.indexOf(",") > -1) {
    spotNames = query.spotName?.replace(/ /g, "");
  } else {
    spotName = query.spotName;
  }

  let agencyCorpUserName = "";
  if (
    query.queryTypes.includes(QUERY_DELIVERY_TYPE_AGENCY_ALLOCATION_KOOKMIN)
  ) {
    agencyCorpUserName = AGENCY_KOOKMIN_CORPTITLE;
  }

  return {
    ...query,
    queryTypes,
    types,
    spotNames,
    spotName,
    agencyCorpUserName,
  };
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const allocateInBulk = useCallback(
    ({ dateFrom, dateTo, type = ALLOCATE_TYPES.PICKUP }) => {
      return api.put(`/deliveries/allocate`, {
        dateFrom,
        dateTo,
        type,
      });
    },
    [api.put],
  );

  const allocateDeliveryRiders = useCallback(
    ({ bookIds, riderId } = {}) =>
      api.put(`/deliveries/delivery-rider-allocation`, {
        bookIds,
        riderId,
      }),
    [api.put],
  );

  const allocatePickupByTypesInBulk = useCallback(
    ({ dateFrom, dateTo }) => {
      return api.put(`/deliveries/allocate/by-types`, {
        dateFrom,
        dateTo,
        allocateType: "PICKUP",
        type: "PICKUP_RETURN",
      });
    },
    [api.put],
  );

  const allocatePickupRiders = useCallback(
    ({ bookIds, riderId } = {}) =>
      api.put(`/deliveries/pickup-rider-allocation`, {
        bookIds,
        riderId,
      }),
    [api.put],
  );

  const cancelByHellovision = useCallback(
    (bookId) => api.put(`/deliveries/${bookId}/lghellovision-cancel`),
    [api.put],
  );

  const cancelForcedBulk = useCallback(
    async ({ data, forced }) => {
      return await Promise.allSettled(
        data.map((delivery) =>
          api.put(`/deliveries/${delivery.bookId}/cancel/forced`, { forced }),
        ),
      );
    },
    [api.put],
  );

  const countByStatus = useCallback(
    async ({ query }) => {
      const _query = {
        ...makeQueryStringObj(query),
        riderId: query.rider ? query.rider.id : "",
      };

      Promise.allSettled([
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "receipted",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "pickupScheduled",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "pickupCompleted",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "warehoused",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "deliveryAllocated",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "deliveryStarted",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "deliveryCompleted",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "deliverySentBack",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "deliveryLost",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "postponed",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "accident",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "canceled",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          types: "2",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          types: "3",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "customCustomerPostalCode",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "senderAddressError",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "senderAddressErrorCanceled",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "receiverAddressError",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "receiverAddressErrorCanceled",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "mobileError",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "mobileErrorCanceled",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "addressNotSupported",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "addressNotSupportedCanceled",
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "",
          agencyCorpUserName: AGENCY_KOOKMIN_CORPTITLE,
        }),
        api.get("/deliveries/count-by-status", {
          ..._query,
          queryTypeForCount: "agencyAllocationFailed",
          // agencyCorpUserName: AGENCY_KOOKMIN_CORPTITLE,
        }),
        api.get("/delayed-deliveries/list", {
          ...makeQueryStringObj(query),
          riderId: query.rider ? query.rider.id : "",
        }),
      ]).then((results) => {
        dispatch({
          type: CONSTANTS.SET_COUNT,
          count: {
            receipted:
              results[0]?.status === "rejected"
                ? null
                : results[0]?.value?.count,
            pickupScheduled:
              results[1]?.status === "rejected"
                ? null
                : results[1]?.value?.count,
            pickupCompleted:
              results[2]?.status === "rejected"
                ? null
                : results[2]?.value?.count,
            warehoused:
              results[3]?.status === "rejected"
                ? null
                : results[3]?.value?.count,
            deliveryAllocated:
              results[4]?.status === "rejected"
                ? null
                : results[4]?.value?.count,
            deliveryStarted:
              results[5]?.status === "rejected"
                ? null
                : results[5]?.value?.count,
            deliveryCompleted:
              results[6]?.status === "rejected"
                ? null
                : results[6]?.value?.count,
            deliverySentBack:
              results[7]?.status === "rejected"
                ? null
                : results[7]?.value?.count,
            deliveryLost:
              results[8]?.status === "rejected"
                ? null
                : results[8]?.value?.count,
            postponed:
              results[9]?.status === "rejected"
                ? null
                : results[9]?.value?.count,
            accident:
              results[10]?.status === "rejected"
                ? null
                : results[10]?.value?.count,
            canceled:
              results[11]?.status === "rejected"
                ? null
                : results[11]?.value?.count,
            typePickup:
              results[12]?.status === "rejected"
                ? null
                : results[12]?.value?.count,
            typeReturn:
              results[13]?.status === "rejected"
                ? null
                : results[13]?.value?.count,
            customCustomerPostalCode:
              results[14]?.status === "rejected"
                ? null
                : results[14]?.value?.count,
            senderAddressError:
              results[15]?.status === "rejected"
                ? null
                : results[15]?.value?.count,
            senderAddressErrorCanceled:
              results[16]?.status === "rejected"
                ? null
                : results[16]?.value?.count,
            receiverAddressError:
              results[17]?.status === "rejected"
                ? null
                : results[17]?.value?.count,
            receiverAddressErrorCanceled:
              results[18]?.status === "rejected"
                ? null
                : results[18]?.value?.count,
            mobileError:
              results[19]?.status === "rejected"
                ? null
                : results[19]?.value?.count,
            mobileErrorCanceled:
              results[20]?.status === "rejected"
                ? null
                : results[20]?.value?.count,
            addressNotSupported:
              results[21]?.status === "rejected"
                ? null
                : results[21]?.value?.count,
            addressNotSupportedCanceled:
              results[22]?.status === "rejected"
                ? null
                : results[22]?.value?.count,
            agencyAllocationKookmin:
              results[23]?.status === "rejected"
                ? null
                : results[23]?.value?.count,
            agencyAllocationFailed:
              results[24]?.status === "rejected"
                ? null
                : results[24]?.value?.count,
          },
          countDelayed:
            results[25]?.status === "rejected"
              ? null
              : results[25]?.value?.totalCount,
        });
      });

      return true;
    },
    [api.get, dispatch],
  );

  const fetchAgencyDelivery = useCallback(
    async ({
      agencyCorpUserName = AGENCY_KOOKMIN_USERNAME,
      agencyOrderId,
      bookId,
    }) => {
      return await api.get(`/agencies/${agencyCorpUserName}/one`, {
        agencyOrderId,
        bookId,
      });
    },
    [api.get],
  );

  const fetchAll = useCallback(
    async ({ query }) => {
      let body = {
        ...makeQueryStringObj(query),
      };

      if (query.rider) {
        body.riderId = query.rider.id;
      }
      const { deliveries, pageCount } = await api.get("/deliveries", body);

      let _deliveries = deliveries.map((d) => new Delivery(d));

      if (state.query.orderingNumberByDeliveryRiderId) {
        _deliveries = _deliveries.filter(
          (e) =>
            Number(e.orderingNumberByDeliveryRiderId) ===
            Number(state.query.orderingNumberByDeliveryRiderId),
        );
      }

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        deliveries: _deliveries,
        deliveriesWholeData: deliveries,
        pageCount,
      });

      return { deliveries, pageCount };
    },
    [api.get, dispatch, state.query.orderingNumberByDeliveryRiderId],
  );

  const fetchAllDelayed = useCallback(
    async ({ query }) => {
      const deliveriesDelayed = await api.get("/delayed-deliveries/list", {
        ...makeQueryStringObj(query),
        riderId: query.rider ? query.rider.id : "",
      });

      dispatch({
        type: CONSTANTS.FETCH_ALL_DELAYED,
        deliveries: deliveriesDelayed.rows.map((d) => new Delivery(d)),
      });

      return { deliveriesDelayed };
    },
    [api.get, dispatch],
  );

  const fetchAllToReallocateOnTheFly = useCallback(
    async ({ deliveryStatus, dongId = "", riderId }) => {
      const { items } = await api.get("/deliveries/allocated", {
        deliveryStatus,
        dongId,
        riderId,
      });

      return items || [];
    },
    [api.get],
  );

  const fetchAllSelectedDeliveries = useCallback(
    async (deliveries) => {
      return await Promise.all(
        deliveries.map((delivery) => api.get(`/deliveries/${delivery.bookId}`)),
      );
    },
    [api.get],
  );

  const fetchAllById = useCallback((id) => api.get(`/deliveries/all`, { id }), [
    api.get,
  ]);

  const fetchByIdOnTheFly = useCallback(
    (bookId) => api.get(`/deliveries/${bookId}`),
    [api.get],
  );

  const fetchDeliveryStatusLogs = useCallback(
    (bookId) => api.get(`/deliveries/${bookId}/delivery-status-logs/all`),
    [api.get],
  );

  // const fetchForDownload = useCallback(
  //   ({ query }) => {
  //     return api.get("/deliveries/download", {
  //       ...makeQueryStringObj(query),
  //       orderBy: query.orderBy,
  //       riderId: query.rider ? query.rider.id : "",
  //     });
  //   },
  //   [api.get],
  // );

  const fetchForDownload = useCallback(
    ({ query }) => {
      return api.get("/deliveries/download", {
        ...makeQueryStringObj(query),
        orderBy: query.orderBy,
        riderId: query.rider ? query.rider.id : "",
      });
    },
    [api.get],
  );

  const fetchForDownloadV2 = useCallback(
    async ({ query }) => {
      const response = await api.get("/v2/deliveries/download", {
        ...makeQueryStringObj(query),
        orderBy: query.orderBy,
        riderId: query.rider ? query.rider.id : "",
      });

      return response;
    },
    [api.get],
  );

  const fetchTokenByIdOnTheFly = useCallback(
    (id) => api.post(`/deliveries/tokens`, { id }),
    [api.post],
  );

  const resetQuery = useCallback(() => {
    dispatch({
      type: CONSTANTS.RESET_QUERY,
    });

    return INITIAL_QUERY;
  }, [dispatch]);

  const reset = useCallback(
    (reset) => {
      dispatch({
        type: CONSTANTS.RESET,
        reset,
      });
    },
    [dispatch],
  );

  const restoreAccident = useCallback(
    (bookIds = []) =>
      api.put(`/deliveries/restore/accident`, {
        bookIds,
      }),
    [api.put],
  );

  const restoreCancel = useCallback(
    (bookIds = []) =>
      api.put(`/deliveries/restore/cancel`, {
        bookIds,
      }),
    [api.put],
  );

  const setDelayReason = useCallback(
    async (selectedDeliveries, data) => {
      return await Promise.allSettled(
        selectedDeliveries.map((delivery) =>
          api.put(`/deliveries/${delivery.bookId}/delay`, data),
        ),
      );
    },
    [api.put],
  );

  const setDeliveryStartedAll = useCallback(
    () => api.put(`/deliveries/set-delivery-started-all`),
    [api.put],
  );

  const setDeliveryStartedInBulk = useCallback(
    (bookIds = []) =>
      api.put(`/deliveries/set-delivery-started-in-bulk`, {
        bookIds,
      }),
    [api.put],
  );

  const setDeliveryCompletedStatus = useCallback(
    (bookIds = [], text = "") =>
      api.put(`/deliveries/delivery-completed`, {
        bookIds,
        reason: text,
      }),
    [api.put],
  );

  const setPreviousStatus = useCallback(
    (bookIds = []) =>
      api.put(`/deliveries/previous`, {
        bookIds,
      }),
    [api.put],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const setRefineAddresses = useCallback(
    (deliveries = []) => {
      return api.put(`/deliveries/refine-addresses`, {
        deliveries,
      });
    },
    [api.put],
  );

  const setReturn = useCallback(
    (bookId) => {
      return api.post(`/deliveries/${bookId}/return`, {
        returningBack: true,
      });
    },
    [api.post],
  );

  const setWarehousedStatus = useCallback(
    (bookIds = []) => {
      return api.put(`/deliveries/warehoused`, {
        bookIds,
      });
    },
    [api.put],
  );

  const setWarehousedStatusBeforePickupBulk = useCallback(
    async (deliveries = []) => {
      return await Promise.all(
        deliveries.map((item) =>
          api.put(`/deliveries/${item.bookId}/warehoused-before-pickup`),
        ),
      );
    },
    [api.put],
  );

  const syncAgencyBulk = useCallback(
    async ({ agencyCorpUserName = AGENCY_KOOKMIN_USERNAME, deliveries }) => {
      return await Promise.allSettled(
        deliveries.map((delivery) =>
          api.put(`/deliveries/${delivery.bookId}/sync-with-agencies`),
        ),
      );
    },
    [api.put],
  );

  const update = useCallback(
    (bookId, delivery = {}) => api.put(`/deliveries/${bookId}`, delivery),
    [api.put],
  );

  const updateAccident = useCallback(
    (bookId, reason = "") =>
      api.put(`/deliveries/${bookId}/accident`, {
        reason,
      }),
    [api.put],
  );

  const updateAddress = useCallback(
    (bookId, data) => api.put(`/deliveries/${bookId}/address`, data),
    [api.put],
  );

  const updateCancel = useCallback(
    (bookId, reason = "") =>
      api.put(`/deliveries/${bookId}/cancel`, {
        reason,
      }),
    [api.put],
  );

  return {
    state,
    allocateDeliveryRiders,
    allocateInBulk,
    allocatePickupByTypesInBulk,
    allocatePickupRiders,
    cancelByHellovision,
    cancelForcedBulk,
    countByStatus,
    fetchAgencyDelivery,
    fetchAll,
    fetchAllDelayed,
    fetchAllToReallocateOnTheFly,
    fetchAllSelectedDeliveries,
    fetchAllById,
    fetchByIdOnTheFly,
    fetchDeliveryStatusLogs,
    fetchForDownload,
    fetchForDownloadV2,
    fetchTokenByIdOnTheFly,
    reset,
    resetQuery,
    restoreAccident,
    restoreCancel,
    setDelayReason,
    setDeliveryCompletedStatus,
    setDeliveryStartedAll,
    setDeliveryStartedInBulk,
    setPreviousStatus,
    setQuery,
    setRefineAddresses,
    setReturn,
    setWarehousedStatus,
    setWarehousedStatusBeforePickupBulk,
    syncAgencyBulk,
    update,
    updateAccident,
    updateAddress,
    updateCancel,
  };
};
